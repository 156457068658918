import axios from "axios";

import { onRequestInterceptor } from "../interceptors/Org";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

api.interceptors.request.use(onRequestInterceptor);

export default api;
